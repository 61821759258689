<template>
    <button v-if="enabled" class="btn btn-light btn-sm" type="button" @click="assumeUser">
        assume
    </button>
</template>

<script>
export default {
    name: "TableCellUsersAssume",
    props: {
        item: Object,
    },
    data() {
        return {
            enabled: false
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
    },
    created() {
        // Remove the button if the user is not an administrator or it's the current user's row.
        this.enabled = (
            this.user.administrator
            && this.user.id !== this.item.id
        )
    },
    methods: {
        assumeUser() {
            this.$store.dispatch('assumeUser/assume', this.item.id)
        }
    },
}
</script>

<style scoped>

</style>