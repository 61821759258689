<template>
    <select v-if="typeOptions.length" v-model="item.operator" class="form-select form-select-sm d-inline-block w-auto">
        <option selected disabled value="">Select&hellip;</option>
        <option v-for="option in typeOptions" :value="option.value">{{ option.text }}</option>
    </select>
</template>

<script>
export default {
    name: "CollectionFilterOperatorMenu",
    props: {
        contentId: Number,
        item: Object
    },
    computed: {
        fieldId() {
            return this.item.fieldId
        },
        fieldType() {
            return this.field?.type
        },
        typeOptions() {
            return this.$store.getters['collectionFilters/typesOptions'](this.fieldType, this.fieldId)
        }
    },
    asyncComputed: {
        field() {
            if (this.fieldId) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'modulestructure',
                    id: this.fieldId
                })
            }
        }
    }
}
</script>

<style scoped>

</style>