<template>
    <div v-if="tableName">
        <div v-if="itemIds" v-for="itemId in itemIds"
             class="mb-1 text-bg-light rounded p-1 small d-flex align-items-center">
            <FormControlCategoryTitle :table-name="tableName" :id="parseInt(itemId)" class="flex-fill"/>
            <button type="button" class="btn btn-sm" @click="removeItem(itemId)"><span class="bi-x"></span></button>
        </div>

        <button class="btn btn-sm btn-white text-start" type="button" @click="openSelectListModal">
            <span class="bi-plus-lg"></span>
        </button>
    </div>

    <small v-else-if="!componentId" class="text-danger form-text ms-2 mb-1 fst-italic small">Error: No
        collection targeted</small>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'

export default {
    name: "CollectionFilterControlTypeRelationship",
    components: {
        FormControlCategoryTitle,
    },
    props: {
        field: Object,
        item: Object
    },
    computed: {
        componentId() {
            return this.contentType?.componentId
        },
        tableName() {
            // The "<Collection> items" field targets
            const componentId = this.item.fieldId === null
                ? this.componentId
                : this.field?.categoryComponentId

            return this.$store.state.components.items.find(o => o.id === componentId)?.tableName
        },
        itemIds() {
            return this.item.value
        }
    },
    asyncComputed: {
        content() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'content',
                id: this.item.contentId
            })
        },
        contentType() {
            if (this.content) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'templates_templates',
                    id: this.content.contentType
                })
            }
        }
    },
    methods: {
        openSelectListModal() {
            this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    // The namespace isolates it from the main listing so its settings are independent.
                    listingName: this.tableName + '.' + this.item.fieldId,
                    selectedIds: this.itemIds || [],
                    onSelect: (selectedIds) => {
                        this.item.value = selectedIds
                    }
                }
            })
        },
        removeItem(itemId) {
            const id = parseInt(itemId)
            this.item.value = this.item.value.filter(o => o !== id)
        }
    }
}
</script>

<style scoped>

</style>