<template>
    <span class="dropdown">
        <span v-if="options.length === 1">
            {{ filter.filterData.conditionText }}
        </span>

        <button v-if="options.length > 1" class="btn btn-light ms-1" data-bs-toggle="dropdown" type="button">
            {{ filter.filterData.conditionText }}
        </button>

        <ul class="dropdown-menu">
            <li v-for="(option, index) in options" :key="option.id" @click="setOption(index)">
                <a :class="{'active': option.text === filter.filterData.conditionText}"
                   class="dropdown-item">{{ option.text }}</a>
            </li>
        </ul>
    </span>
</template>

<script>
export default {
    name: "SearchFilterColumnOptions",
    props: {
        viewId: String,
        listingName: String,
        filter: Object,
        columns: Array
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        tableName() {
            return this.listingName.split('.')[0]
        },
        column() {
            return this.columns.find(o => o.columnName === this.filter.filterData.columnName)
        },
        options() {
            let options = []

            if (this.column) {
                options = this.$store.getters['advancedFilters/typesOptions'](this.column.type)
            }

            this.updateFilterData(options)

            return options
        }
    },
    created() {
        // The IS SET and IS NOT SET conditions also set their values because they do not have fields for storing values.
        // This value must be unset when returning to the advanced filters.
        let filterData = this.filter.filterData

        if (
            (filterData.condition === 'is set' || filterData.condition === 'is not set')
            && this.filters.advancedFilter[this.filter.id] === undefined
        ) {
            filterData.condition = ''
        }

        if (
            filterData.type === 'relationshipOneToMany'
            && (filterData.condition === 'one is set' || filterData.condition === 'one is not set')
            && this.filters.advancedFilter[this.filter.id] === undefined
        ) {
            filterData.condition = ''
        }

        if (
            filterData.type === 'relationshipManyToMany'
            && (filterData.condition === 'many is set' || filterData.condition === 'many is not set')
            && this.filters.advancedFilter[this.filter.id] === undefined
        ) {
            filterData.condition = ''
        }
    },
    methods: {
        updateFilterData(options) {
            let filterData = this.filter.filterData

            // todo - There seems to be a lot of duplication in the scripts below and in setOption.
            //        Can I simplify it?

            let option
            if (filterData.condition) {
                option = options.filter((option) => {
                    return option.condition === filterData.condition
                })[0]
            } else {
                option = options[0]
                filterData.condition = option.condition
                filterData.conditionText = option.text
            }

            filterData.type = option.type
        },
        setOption(i) {
            let filterData = this.filter.filterData

            let option = this.options[i]
            filterData.condition = option.condition
            filterData.conditionText = option.text
            filterData.type = option.type

            this.$store.dispatch('advancedFilters/save', {
                    tableName: this.tableName,
                    id: this.filter.id,
                    filterData: filterData,
                })
                .then(() => {
                    if (
                        filterData.condition === 'is set'
                        || filterData.condition === 'is not set'
                    ) {
                        filterData.value = filterData.condition

                        this.$store.commit(this.viewId + '/setFilter', {
                            id: this.filter.id,
                            value: filterData.value
                        })

                    } else if (
                        option.type === 'relationshipOneToMany'
                        && option.condition === 'one is set'
                    ) {
                        filterData.value = 'IS SET'

                        this.$store.commit(this.viewId + '/setFilter', {
                            id: this.filter.id,
                            value: filterData.value
                        })
                    } else if (
                        option.type === 'relationshipOneToMany'
                        && option.condition === 'one is not set'
                    ) {
                        filterData.value = 'IS NOT SET'

                        this.$store.commit(this.viewId + '/setFilter', {
                            id: this.filter.id,
                            value: filterData.value
                        })
                    }

                    if (
                        option.type === 'relationshipManyToMany'
                        && option.condition === 'many is set'
                    ) {
                        filterData.value = 'IS SET'

                        this.$store.commit(this.viewId + '/setFilter', {
                            id: this.filter.id,
                            value: filterData.value
                        })
                    } else if (
                        option.type === 'relationshipManyToMany'
                        && option.condition === 'many is not set'
                    ) {
                        filterData.value = 'IS NOT SET'

                        this.$store.commit(this.viewId + '/setFilter', {
                            id: this.filter.id,
                            value: filterData.value
                        })
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>