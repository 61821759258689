<template>
    <div class="dropdown d-inline-block">
        <button class="btn btn-light" data-bs-toggle="dropdown" type="button">
            {{ columnTitle }}
        </button>

        <ul class="dropdown-menu pt-0">
            <template v-for="fieldset in fieldsets">
                <li class="dropdown-item disabled" :class="{'pt-3': fieldset.legend !== '&nbsp;'}" v-html="fieldset.legend"/>
                <li v-for="column in getColumns(fieldset.id)" :key="column.id" @click="setColumn(column.columnName)">
                    <a :class="{'active': column.columnName === columnName}" class="dropdown-item">
                        {{ column.title }}
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: "SearchFilterColumnMenu",
    props: {
        listingName: String,
        filter: Object,
        columns: Array,
        setFilterItems: Function,
        viewId: String,
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        tableName() {
            return this.listingName.split('.')[0]
        },
        columnName() {
            return this.filter.filterData.columnName
        },
        columnTitle() {
            return this.columns.find(o => o.columnName === this.columnName)?.title
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        fieldsets() {
            const fieldsets = this.$store.state.fieldsets.items.filter(o => o.componentId === this.componentId)

            fieldsets.push({
                id: 0,
                legend: '&nbsp;',
            })

            return fieldsets
        }
    },
    methods: {
        getColumns(fieldsetId) {
            return this.columns.filter(o => o.fieldsetId === fieldsetId)
        },
        setColumn(columnName) {
            // Unset the previously set value, otherwise it might conflict with the new field's props.
            // For example, the relationshipOneToMany field's component expects a String value.
            this.filters.advancedFilter[this.filter.id] = undefined

            let filterData = this.filter.filterData

            filterData.columnName = columnName
            filterData.condition = false

            let columns = this.columns.filter((column) => {
                return column.columnName === columnName
            })

            if (columns) {
                let column = columns[0]
                let options = this.$store.getters['advancedFilters/typesOptions'](column.type)

                filterData.condition = options[0].condition
                filterData.conditionText = options[0].text
            }

            this.$store.dispatch('advancedFilters/save', {
                    tableName: this.tableName,
                    id: this.filter.id,
                    filterData: filterData,
                })
                .then(() => {
                    this.setFilterItems(true)
                })
        }
    }
}
</script>

<style scoped>

</style>