<template>
    <button v-if="!showInput" class="btn btn-light btn-sm" type="button" @click="showPasswordField">
        Change password
    </button>

    <div v-else class="row g-0 align-items-center">
        <div class="col-auto">
            <input
                ref="passwordInput"
                v-model="currentData[field.name]"
                :name="field.name"
                :size="field.size"
                :type="isPasswordTextVisible ? 'text' : 'password'"
                class="form-control"
                required
            />
        </div>

        <div class="col-auto btn-group">
            <button
                class="btn btn-light btn-sm"
                type="button"
                @click="isPasswordTextVisible = !isPasswordTextVisible"
            >
                {{ isPasswordTextVisible ? 'hide password' : 'show password' }}
            </button>

            <button
                class="btn btn-light btn-sm"
                type="button"
                @click="hidePasswordField"
            >
                reset
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormControlTypePassword",
    props: {
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            showInput: false,
            storedPassword: undefined,
            isPasswordTextVisible: false,
        }
    },
    methods: {
        showPasswordField() {

            this.showInput = true

            this.storedPassword = this.currentData[this.field.name]
            this.currentData[this.field.name] = ''

            this.$nextTick(() => {
                this.$refs.passwordInput.focus()
            })
        },
        hidePasswordField() {
            this.showInput = false
            this.currentData[this.field.name] = this.storedPassword
            this.isPasswordTextVisible = true
        }
    }
}
</script>

<style scoped>

</style>