<script>
import FormControlTypeCheckbox from '../form-control-type/FormControlTypeCheckbox'

export default {
    name: "FormControlTemplatesTemplatesLazyLoad",
    extends: FormControlTypeCheckbox,
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    created() {
        if (!this.$store.state.betaMode) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>

</style>