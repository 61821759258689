<template>
    <FormSidebarBtn v-if="show" :disabled="isDisabled" @click.prevent.native="onClick">
        <i class="me-2 bi-subtract"/>
        Duplicate
    </FormSidebarBtn>
</template>

<script>
import FormSidebarBtn from "./FormSidebarBtn.vue"

export default {
    name: "FormDuplicateBtn",
    components: {FormSidebarBtn},
    props: {
        formId: String,
    },
    data() {
        return {
            isDisabled: false
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        show() {
            return (
                this.id // Don't show when creating a new item
                //&& this.tableName !== 'product_types'
                && this.tableName !== 'account_settings'
                && this.tableName !== 'product_options'
                && this.tableName !== 'product_variables'
                && this.tableName !== 'm_basket_orders'
                && this.tableName !== 'm_registrants_registrants'
                && this.tableName !== 'users'
            )
        },
        isSiteTree() {
            return this.$route.path.split('/')[1] === 'site_tree'
        },
        pageContentId() {
            const part = this.$route.path.split('/').pop()
            return (part.indexOf('c') === 0) ? parseInt(part.substring(1)) : 0
        }
    },
    methods: {
        onClick() {
            if (!confirm('Are you sure?')) {
                return
            }

            this.isDisabled = true

            this.$store.dispatch('request/post', {
                    url: 'api/component/' + this.tableName + '?copyId=' + this.id + '&pageContentId=' + this.pageContentId
                })
                .then((obj) => {
                    let id = obj.data.id

                    if (
                        this.tableName === 'pages'
                        || this.tableName === 'content'
                    ) {
                        // Refresh site tree pane
                        this.$store.dispatch('siteTree/init')
                    }

                    if (this.isSiteTree) {
                        this.$store.dispatch('pageContentData/init')
                            .then(() => {
                                this.isDisabled = false

                                if (this.tableName === 'content') {
                                    const obj = this.$store.state.pageContentData.items.find(o => o.contentId === id)
                                    if (obj) {
                                        this.$router.push({
                                            name: 'siteTree',
                                            params: {
                                                pageOrContentId: 'c' + obj.id
                                            },
                                            query: this.$route.query // Retain the query string
                                        })
                                    }
                                } else {
                                    this.$router.push({
                                        name: 'siteTree',
                                        params: {
                                            pageOrContentId: id
                                        },
                                        query: this.$route.query // Retain the query string
                                    })
                                }
                            })
                    } else {
                        this.isDisabled = false

                        this.$router.push({
                            name: 'form',
                            params: {
                                //component: this.tableName,
                                id: id
                            },
                            query: this.$route.query // Retain the query string
                        })
                    }
                })
        },
    }
}
</script>

<style scoped>

</style>