<template>
    <button v-if="show && tableName" class="btn btn-sm btn-light text-start" type="button" @click="selectCategory">
        <span v-if="!item.value">select</span>

        <FormControlCategoryTitle v-if="itemId" :table-name="tableName" :id="itemId"/>
    </button>

    <small v-else-if="show && !componentId" class="text-danger form-text ms-2 mb-1 fst-italic small">Error: No
        collection targeted</small>
</template>

<script>
import FormControlCategoryTitle from '../../common/form-control/FormControlCategoryTitle'

export default {
    name: "CollectionFilterControlTypeRelationship",
    components: {
        FormControlCategoryTitle,
    },
    props: {
        field: Object,
        item: Object
    },
    computed: {
        componentId() {
            return this.contentType?.componentId
        },
        tableName() {
            // The "<Collection> items" field targets
            const componentId = this.item.fieldId === null
                ? this.componentId
                : this.field?.categoryComponentId

            return this.$store.state.components.items.find(o => o.id === componentId)?.tableName
        },
        itemId() {
            return parseInt(this.item.value)
        },
        show() {
            return ['is associated to', 'is not associated to'].indexOf(this.item.operator) > -1
        }
    },
    asyncComputed: {
        content() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'content',
                id: this.item.contentId
            })
        },
        contentType() {
            if (this.content) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'templates_templates',
                    id: this.content.contentType
                })
            }
        }
    },
    methods: {
        selectCategory() {
            let modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    // The namespace isolates it from the main listing so its settings are independent.
                    listingName: this.tableName + '.' + this.item.fieldId,
                    selectedIds: this.itemId ? [this.itemId] : [],
                    onSelect: (selectedIds) => {
                        this.item.value = selectedIds.pop()

                        // As relationshipOneToMany only allow one item to be selected, close the modal when the
                        // user makes a selection.
                        modal.then((obj) => {
                            this.$store.dispatch('modals/remove', obj.index)
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>

</style>