<template>
    <component :is="componentName" :current-data="currentData" :field="field" :form-id="formId"
               :value="currentData[field.name]"/>
</template>

<script>
import camelCase from 'lodash/camelCase'
// Bespoke components
import FormControlCoursesDatesBookings from "../../bespoke/flyspain/FormControlCoursesDatesBookings"
import FormControlMRegistrantsRegistrantsCreditAmount
    from "../../bespoke/odp/FormControlMRegistrantsRegistrantsCreditAmount"
import FormControlOdpCourseBookingsAmountPaid from "../../bespoke/odp/FormControlOdpCourseBookingsAmountPaid"
import FormControlOdpCourseBookingsCourseDateId from "../../bespoke/odp/FormControlOdpCourseBookingsCourseDateId"
import FormControlOdpCourseDatesPromotionId from "../../bespoke/odp/FormControlOdpCourseDatesPromotionId"
import FormControlOdpCourseDatesCourseId from "../../bespoke/odp/FormControlOdpCourseDatesCourseId"
import FormControlOdpCourseDatesBookings from "../../bespoke/odp/FormControlOdpCourseDatesBookings"
import FormControlOdpCourseDatesEvent from "../../bespoke/odp/FormControlOdpCourseDatesEvent"
import FormControlOdpCreditLogFranchiseId from "../../bespoke/odp/FormControlOdpCreditLogFranchiseId"

// Custom field components
import FormControlActivityRowId from "../form-control/FormControlActivityRowId.vue"
import FormControlUsersAdministrator from "../form-control/FormControlUsersAdministrator"
import FormControlUsersRoles from "../form-control/FormControlUsersRoles"
import FormControlUsersGroupsRoles from "../form-control/user-groups-roles/FormControlUsersGroupsRoles"
import FormControlProductVariablesOptionId from "../form-control/FormControlProductVariablesOptionId"
import FormControlProductTypesButtons from "../form-control/FormControlProductTypesButtons"
import FormControlProductTypesShipping from "../form-control/FormControlProductTypesShipping"
import FormControlProductOptionsProductTypeId from "../form-control/FormControlProductOptionsProductTypeId"
import FormControlMProductsProductsTypeId from "../form-control/FormControlMProductsProductsTypeId"
import FormControlMProductsProductsCategoryId from "../form-control/FormControlMProductsProductsCategoryId"
import FormControlPagesUrlSlug from "../form-control/FormControlPagesUrlSlug"
import FormControlPagesUrl from "../form-control/FormControlPagesUrl"
import FormControlPagesTemplateId from "../form-control/FormControlPagesTemplateId"
import FormControlPagesNavigation from "../form-control/FormControlPagesNavigation"
import FormControlPagesLayout from "../form-control/FormControlPagesLayout"
import FormControlPagesContent from "../form-control/FormControlPagesContent"
import FormControlMRegistrantsRegistrantsFormEnquiries
    from "../form-control/FormControlMRegistrantsRegistrantsFormEnquiries"
import FormControlMRegistrantsRegistrantsLogin from "../form-control/FormControlMRegistrantsRegistrantsLogin"
import FormControlMRegistrantsRegistrantsUsername from "../form-control/FormControlMRegistrantsRegistrantsUsername"
import FormControlMBasketMerchantdetailsSettings from "../form-control/FormControlMBasketMerchantdetailsSettings"

import FormControlModulecomponentsDisplayName from "../form-control/FormControlModulecomponentsDisplayName"
import FormControlModulecomponentsNameField from "../form-control/FormControlModulecomponentsNameField.vue"
import FormControlModulecomponentsTitleColumn from "../form-control/FormControlModulecomponentsTitleColumn"
import FormControlModulecomponentsThumbnailColumn from "../form-control/FormControlModulecomponentsThumbnailColumn"
import FormControlModulecomponentsTableName from "../form-control/FormControlModulecomponentsTableName"
import FormControlModulecomponentsCategoryColumn from "../form-control/FormControlModulecomponentsCategoryColumn"
import FormControlAccountSettingsSecondaryDomains from "../form-control/FormControlAccountSettingsSecondaryDomains"
import FormControlAccountSettingsPrimaryDomain from "../form-control/FormControlAccountSettingsPrimaryDomain"
import FormControlAccountSettingsReorderScripts from "../form-control/FormControlAccountSettingsReorderScripts"
import FormControlAccountSettingsDefaultContentLayout
    from "../form-control/FormControlAccountSettingsDefaultContentLayout"
import FormControlContentPageUse from "../form-control/FormControlContentPageUse"
import FormControlContentClass from "../form-control/FormControlContentClass.vue"
import FormControlContentContentType from "../form-control/FormControlContentContentType"
import FormControlContentContentTargetContentArea from "../form-control/FormControlContentContentTargetContentArea"

import FormControlMBasketOrderitemsVariationId from "../form-control/FormControlMBasketOrderitemsVariationId"
import FormControlMBasketOrdersCustomerEmailMessageId
    from "../form-control/FormControlMBasketOrdersCustomerEmailMessageId"
import FormControlMBasketOrdersInternalEmailMessageId
    from "../form-control/FormControlMBasketOrdersInternalEmailMessageId"
import FormControlMBasketOrdersLogs from "../form-control/FormControlMBasketOrdersLogs"
import FormControlMBasketOrdersRequestAReview from "../form-control/FormControlMBasketOrdersRequestAReview"
import FormControlMBasketOrdersQuantity from "../form-control/FormControlMBasketOrdersQuantity"
import FormControlMProductsProductsShipping from "../form-control/FormControlMProductsProductsShipping"
import FormControlModulestructureBrowser from "../form-control/FormControlModulestructureBrowser"
import FormControlModulestructureCategoryComponentId
    from "../form-control/FormControlModulestructureCategoryComponentId"
import FormControlModulestructureColumnName from "../form-control/FormControlModulestructureColumnName"
import FormControlModulestructureDateFormat from "../form-control/FormControlModulestructureDateFormat"
import FormControlModulestructureDefaultValue from "../form-control/FormControlModulestructureDefaultValue"
import FormControlModulestructureEndYear from "../form-control/FormControlModulestructureEndYear"
import FormControlModulestructureStartYear from "../form-control/FormControlModulestructureStartYear"
import FormControlModulestructureNumRows from "../form-control/FormControlModulestructureNumRows"
import FormControlModulestructureOptions from "../form-control/FormControlModulestructureOptions"
import FormControlModulestructurePassword from "../form-control/FormControlModulestructurePassword"
import FormControlModulestructureSelectType from "../form-control/FormControlModulestructureSelectType"
import FormControlModulestructureType from "../form-control/FormControlModulestructureType"
import FormControlModulestructureTableTargetColumn from "../form-control/FormControlModulestructureTableTargetColumn"
import FormControlStockQuantity from "../form-control/FormControlStockQuantity"
import FormControlUrlSlug from "../form-control/FormControlUrlSlug"
import FormControlPortalSitesBuildName from "../form-control/FormControlPortalSitesBuildName"
import FormControlEmailMessageAttachments from "../form-control/FormControlEmailMessageAttachments"
import FormControlEmailMessageEvent from "../form-control/FormControlEmailMessageEvent"
import FormControlEmailMessageEventDate from "../form-control/FormControlEmailMessageEventDate"
import FormControlEmailMessageHtmlBody from "../form-control/FormControlEmailMessageHtmlBody"
import FormControlVariationsOption from "../form-control/FormControlVariationsOption"
import FormControlTagsTagUsage from '../form-control/form-control-tags-usage/FormControlTagsTagUsage'
import FormControlThemesCssGroups from '../form-control/FormControlThemesCssGroups'
import FormControlTemplatesTemplatesComponentId from '../form-control/FormControlTemplatesTemplatesComponentId'
import FormControlTemplatesTemplatesContentType from '../form-control/FormControlTemplatesTemplatesContentType'
import FormControlTemplatesTemplatesCssGroupName from '../form-control/FormControlTemplatesTemplatesCssGroupName'
import FormControlTemplatesTemplatesDisplayInSitemap from '../form-control/FormControlTemplatesTemplatesDisplayInSitemap'
import FormControlTemplatesTemplatesDetailsPageLayout from '../form-control/FormControlTemplatesTemplatesDetailsPageLayout'
import FormControlTemplatesTemplatesFilterAttribute from '../form-control/FormControlTemplatesTemplatesFilterAttribute'
import FormControlTemplatesTemplatesLazyLoad from '../form-control/FormControlTemplatesTemplatesLazyLoad'
import FormControlFilesDirectory from '../form-control/FormControlFilesDirectory'
import FormControlFilesFileName from '../form-control/FormControlFilesFileName'
import FormControlFilesFileSize from '../form-control/FormControlFilesFileSize'
import FormControlFilesUsage from '../form-control/FormControlFilesUsage'
import FormControlFranchiseId from '../form-control/FormControlFranchiseId'

// Type components
import FormControlTypeCheckbox from "../form-control-type/FormControlTypeCheckbox"
import FormControlTypeColor from "../form-control-type/FormControlTypeColor"
import FormControlTypeCodeEditor from "../form-control-type/FormControlTypeCodeEditor"
import FormControlTypeCollectionFilters from "../form-control-type/FormControlTypeCollectionFilters.vue"
import FormControlTypeCurrency from "../form-control-type/FormControlTypeCurrency"
import FormControlTypeDate from "../form-control-type/FormControlTypeDate"
import FormControlTypeDateDate from "../form-control-type/FormControlTypeDateDate"
import FormControlTypeDatetimeLocal from "../form-control-type/FormControlTypeDatetimeLocal"
import FormControlTypeEmail from "../form-control-type/FormControlTypeEmail"
import FormControlTypeFile from "../form-control-type/FormControlTypeFile"
import FormControlTypeImage from "../form-control-type/FormControlTypeImage"
import FormControlTypeInternalLink from "../form-control-type/FormControlTypeInternalLink"
import FormControlTypeJson from "../form-control-type/FormControlTypeJson"
import FormControlTypeMonth from "../form-control-type/FormControlTypeMonth"
import FormControlTypeNumber from "../form-control-type/FormControlTypeNumber"
import FormControlTypePassword from "../form-control-type/FormControlTypePassword"
import FormControlTypeRange from "../form-control-type/FormControlTypeRange"
import FormControlTypeRelationshipOneToMany from "../form-control-type/FormControlTypeRelationshipOneToMany"
import FormControlTypeRelationshipManyToMany from "../form-control-type/FormControlTypeRelationshipManyToMany"
import FormControlTypeSelect from "../form-control-type/FormControlTypeSelect"
import FormControlTypeSelectOther from "../form-control-type/FormControlTypeSelectOther"
import FormControlTypeTable from "../form-control-type/FormControlTypeTable"
import FormControlTypeTel from "../form-control-type/FormControlTypeTel"
import FormControlTypeText from "../form-control-type/FormControlTypeText"
import FormControlTypeTextarea from "../form-control-type/FormControlTypeTextarea"
import FormControlTypeTexteditor from "../form-control-type/FormControlTypeTexteditor"
import FormControlTypeTime from "../form-control-type/FormControlTypeTime"
import FormControlTypeUrl from "../form-control-type/FormControlTypeUrl"
import FormControlTypeWeek from "../form-control-type/FormControlTypeWeek"

export default {
    name: "FormControl",
    components: {
        // Bespoke
        FormControlCoursesDatesBookings,
        FormControlOdpCourseBookingsAmountPaid,
        FormControlMRegistrantsRegistrantsCreditAmount,
        FormControlOdpCourseBookingsCourseDateId,
        FormControlOdpCourseDatesPromotionId,
        FormControlOdpCourseDatesCourseId,
        FormControlOdpCourseDatesBookings,
        FormControlOdpCourseDatesEvent,
        FormControlOdpCreditLogFranchiseId,

        FormControlActivityRowId,
        FormControlUsersAdministrator,
        FormControlUsersRoles,
        FormControlUsersGroupsRoles,
        FormControlProductVariablesOptionId,
        FormControlProductTypesButtons,
        FormControlProductTypesShipping,
        FormControlProductOptionsProductTypeId,
        FormControlMProductsProductsTypeId,
        FormControlPagesUrlSlug,
        FormControlPagesUrl,
        FormControlPagesTemplateId,
        FormControlPagesNavigation,
        FormControlPagesLayout,
        FormControlPagesContent,
        FormControlMRegistrantsRegistrantsFormEnquiries,
        FormControlMRegistrantsRegistrantsLogin,
        FormControlMRegistrantsRegistrantsUsername,
        FormControlMBasketMerchantdetailsSettings,
        FormControlMBasketOrderitemsVariationId,
        FormControlMBasketOrdersCustomerEmailMessageId,
        FormControlMBasketOrdersInternalEmailMessageId,
        FormControlMBasketOrdersLogs,
        FormControlMBasketOrdersRequestAReview,
        FormControlMBasketOrdersQuantity,
        FormControlModulecomponentsDisplayName,
        FormControlModulecomponentsNameField,
        FormControlModulecomponentsTitleColumn,
        FormControlModulecomponentsThumbnailColumn,
        FormControlModulecomponentsTableName,
        FormControlModulecomponentsCategoryColumn,
        FormControlAccountSettingsPrimaryDomain,
        FormControlAccountSettingsSecondaryDomains,
        FormControlAccountSettingsReorderScripts,
        FormControlAccountSettingsDefaultContentLayout,
        FormControlContentPageUse,
        FormControlContentClass,
        FormControlContentContentType,
        FormControlContentContentTargetContentArea,
        FormControlMProductsProductsCategoryId,
        FormControlMProductsProductsShipping,
        FormControlModulestructureBrowser,
        FormControlModulestructureCategoryComponentId,
        FormControlModulestructureColumnName,
        FormControlModulestructureDateFormat,
        FormControlModulestructureDefaultValue,
        FormControlModulestructureEndYear,
        FormControlModulestructureStartYear,
        FormControlModulestructureNumRows,
        FormControlModulestructureOptions,
        FormControlModulestructurePassword,
        FormControlModulestructureSelectType,
        FormControlModulestructureTableTargetColumn,
        FormControlModulestructureType,
        FormControlStockQuantity,
        FormControlTemplatesTemplatesComponentId,
        FormControlTemplatesTemplatesContentType,
        FormControlTemplatesTemplatesCssGroupName,
        FormControlTemplatesTemplatesDetailsPageLayout,
        FormControlTemplatesTemplatesDisplayInSitemap,
        FormControlTemplatesTemplatesFilterAttribute,
        FormControlTemplatesTemplatesLazyLoad,
        FormControlThemesCssGroups,
        FormControlUrlSlug,
        FormControlPortalSitesBuildName,
        FormControlEmailMessageAttachments,
        FormControlEmailMessageEvent,
        FormControlEmailMessageEventDate,
        FormControlEmailMessageHtmlBody,
        FormControlTagsTagUsage,
        FormControlVariationsOption,
        FormControlFilesDirectory,
        FormControlFilesFileName,
        FormControlFilesFileSize,
        FormControlFilesUsage,
        FormControlFranchiseId,

        FormControlTypeCheckbox,
        FormControlTypeColor,
        FormControlTypeCodeEditor,
        FormControlTypeCollectionFilters,
        FormControlTypeCurrency,
        FormControlTypeDate,
        FormControlTypeDateDate,
        FormControlTypeDatetimeLocal,
        FormControlTypeEmail,
        FormControlTypeFile,
        FormControlTypeImage,
        FormControlTypeInternalLink,
        FormControlTypeJson,
        FormControlTypeMonth,
        FormControlTypeNumber,
        FormControlTypePassword,
        FormControlTypeRange,
        FormControlTypeRelationshipOneToMany,
        FormControlTypeRelationshipManyToMany,
        FormControlTypeSelect,
        FormControlTypeSelectOther,
        FormControlTypeTable,
        FormControlTypeTel,
        FormControlTypeText,
        FormControlTypeTextarea,
        FormControlTypeTexteditor,
        FormControlTypeTime,
        FormControlTypeUrl,
        FormControlTypeWeek,
    },
    props: {
        formId: String,
        field: Object,
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        component() {
            return this.$store.state.componentRoot.component
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
    },
    data() {
        return {
            componentName: undefined
        }
    },
    created() {
        // todo - This should be moved into the form service.
        // Hack to remove old fields which need to be deleted from components.
        let toBeDeleted = [
            {tableName: 'users', fieldNames: ['assume']},
            {tableName: 'modulestructure', fieldNames: ['customItem']},
            {tableName: 'm_products_products', fieldNames: ['variations']},
        ]
        let removed = false
        toBeDeleted.forEach((component) => {
            if (
                this.tableName === component.tableName
                && component.fieldNames.indexOf(this.field.columnName) > -1
            ) {
                this.field.hidden = true
                removed = true
            }
        })
        if (removed) {
            return
        }

        this.setType()

        this.updateField()

        // MySQL stores this value as 1|0 but Vue's v-bind: requires true|false otherwise an empty attribute
        // will be added, which results in the field being required.
        this.field.required = !!this.field.required
        this.field.disabled = !!this.field.disabled
        this.field.autofocus = !!this.field.autofocus
        this.field.readonly = !!this.field.readonly

        // All forms components fields should be read only.
        // The field's readonly cannot be used as this would make the web form field's readonly
        if (
            this.component.allowFormAccess
            && this.component.navigationGroup === 'Forms' // Avoids Customers being included
            && this.$route.name === 'form' // Prevent the fields route being affected
        ) {
            this.field.readonly = true
        }

        if (
            this.field.name === 'urlSlug'
            && this.tableName !== 'pages'
        ) {
            this.componentName = 'FormControlUrlSlug'
            return
        } else if (this.field.name === 'stockQuantity') {
            this.componentName = 'FormControlStockQuantity'
            return
        }

        // First search for a custom tableName + columnName component.
        if (this.field.name) {
            let tableName = this.tableName.toLowerCase().replace(/_/g, '-')
            let columnName = this.field.name

            // Franchise component
            if (columnName === 'franchiseId' && tableName !== 'users') {
                let componentName = 'FormControlFranchiseId'
                if (componentName in this.$options.components) {
                    this.componentName = componentName
                    console.log('componentName', componentName)
                    return
                }
            }

            // Product variations support.
            if (this.tableName.indexOf('product_variations__') === 0) {
                tableName = 'variations'
                if (this.field.name.indexOf('option') === 0) {
                    columnName = 'option'
                }
            }

            // Vue components
            let componentName = 'form-control-' + tableName + '-' + columnName
            //console.log('componentName', componentName);
            let camelcase = camelCase(componentName) // E.g. formControlMBasketOrdersFieldName
            let vueComponentName = camelcase.charAt(0).toUpperCase() + camelcase.slice(1)
            //console.log(this.field.name, vueComponentName);
            if (vueComponentName in this.$options.components) {
                this.componentName = vueComponentName
                //console.log('vueComponentName', vueComponentName);
                return
            }
        }

        if (['hidden', 'fieldset'].indexOf(this.field.type) > -1) {
            this.field.hidden = true
            return false
        }

        // If no custom component existed then output the field's type component.

        // Vue components
        let typeName = 'form-control-type-' + this.field.type
        let camelcase = camelCase(typeName) // E.g. formControlTypeCheckbox
        let vueComponentName = camelcase.charAt(0).toUpperCase() + camelcase.slice(1)
        //console.log(this.field.name, vueComponentName);
        if (vueComponentName in this.$options.components) {
            this.componentName = vueComponentName
            //console.log('vueComponentName', vueComponentName);
            return
        }

        console.warn('form-control component not found', {
            'field.name': this.field.name,
            vueComponentName: vueComponentName,
        })
    },
    methods: {
        setType() {
            // If the select with other option is in use then we want to target a unique
            // template which incorporates the normal select directive within it
            if (this.field.type === 'select') {
                let selectType = parseInt(this.field.selectType)
                if (
                    this.field.otherInput // Set in content type template
                    || selectType === 1 // DEPRECATED - Legacy data from modulestructure
                ) {
                    this.field.type = 'select-other'
                } else if (selectType === 2) {
                    this.field.type = 'switch-selects'
                }
            }
        },
        updateField() {

            this.field.name = this.field.name || this.field.columnName

            /* This was bad because it overwrites the field's id, which is referenced when sourcing the
               field's data. It also caused Vue to recompile the components as FormFieldset uses :key="field.id".
               In disabling this it means that label's for and control's id attributes are targeting numbers, when
               id attributes are supposed to start with a letter, but it doesn't seem to cause any side effects.
            // Ids must be strings, so prefix numeric ones with a string
            if (
                this.field.id
                && typeof this.field.id === 'number'
            ) {
                this.field.id = 'form-element-' + this.field.id;
            }
            //*/
        }
    }
}
</script>

<style scoped>

</style>