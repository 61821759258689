<template>

</template>

<script>
export default {
    name: "FormControlContentContentType",
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            // The ID of the row from the content type's component_<id> table. This is required by the
            // relationshipManyToMany field for sourcing the IDs
            // todo - This would be redundant if the API provided the latest category IDs in the response.
            //        It would also remove the need for
            contentComponentsItemId: 0,
        }
    },
    computed: {
        componentIds() {
            return this.$store.state.components.componentIds
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        }
    },
    created() {
        this.field.hidden = true

        let tableName = 'component_' + this.currentData.contentType
        let componentId = this.componentIds[tableName]

        // Load the data before building the fields, so it's available for when the fields are created.
        this.setFormData(tableName)
            .finally(() => {
                this.setContentFormFields(componentId)
            })
    },
    methods: {
        setContentFormFields(componentId) {
            let template

            this.$store.dispatch('itemData/get', {
                    tableName: 'templates_templates',
                    id: this.currentData.contentType
                })
                .then((obj) => {
                    template = obj

                    return this.$store.dispatch('componentStructure/get', componentId)
                })
                .then((objs) => {
                    let fields = JSON.parse(JSON.stringify(objs))

                    // Remove the contentId field
                    fields = fields.filter(o => o.columnName !== 'contentId')

                    let displayOrder = 2 // Place after title field
                    fields.forEach((field) => {
                        field.contentComponentsItemId = this.contentComponentsItemId

                        field.displayOrder = displayOrder
                        displayOrder++

                        // To appear the fieldsets must be assigned to a fieldset
                        let fieldsets = this.$store.state.fieldsets.items
                        if (fieldsets.length) {
                            const firstFieldset = fieldsets.find(o => o.componentId === this.componentId)
                            field.fieldsetId = firstFieldset.id
                        }

                        // This is a fix to prevent the fields being added twice. They're being added twice because
                        // the form fields are being rendered twice for some reason.
                        let hasField = this.$store.state[this.formId].fields.find(o => o.columnName === field.columnName)
                        if (!hasField) {
                            this.$store.commit(this.formId + '/addField', {
                                field: field
                            })
                        }
                    })
                })
        },
        setFormData(tableName) {
            return this.$store.dispatch('request/get', {
                    url: 'api/component/' + tableName,
                    params: {
                        contentId: this.id
                    }
                })
                .then((obj) => {
                    if (obj.data.items.length) {
                        let item = obj.data.items[0]

                        // Retain the ID of the row for providing the
                        this.contentComponentsItemId = item.id

                        // Remove the properties of fields which should not be embedded
                        delete item.id
                        delete item.contentId
                        delete item[tableName + 'ID']

                        for (let prop in item) {
                            if (item.hasOwnProperty(prop)) {
                                this.$store.commit(this.formId + '/presetData', {
                                    name: prop,
                                    value: item[prop]
                                })
                            }
                        }
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>