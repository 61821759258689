<template>
    <CodemirrorShowModalBtn v-if="field.codeEditorButtonText" :current-data="currentData" :field="field"
                            :form-id="formId" :modal-title="modalTitle" :mode="mode"/>

    <!-- Without this div CodeMirror replaced the component's parent element. For example,
         FormControlEmailMessageHtmlBody, which embeds it in tabs, resulted in it replacing the tab pane's div, which
         broke the tabs. -->
    <div v-else-if="!field.codeEditorButtonText">
        <textarea ref="codeMirror" :value="value" style="display: none"></textarea>
    </div>
</template>

<script>
import CodemirrorShowModalBtn from '../../common/codemirror/CodemirrorShowModalBtn'

export default {
    name: "FormControlTypeCodeEditor",
    components: {
        CodemirrorShowModalBtn,
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            mode: undefined,
            value: undefined,
        }
    },
    computed: {
        originalData() {
            return this.$store.state[this.formId].originalData
        },
        componentId() {
            return this.$store.state.components.componentIds[this.tableName]
        },
        component() {
            return this.$store.state.components.items.find(o => o.id === this.componentId)
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        nameField() {
            return this.$store.getters['components/nameField'](this.componentId)
        },
        modalTitle() {
            if (this.item) {
                return this.component.title +
                    '<span style="padding: 0 10px; color: #999">></span>' +
                    this.item[this.nameField] +
                    '<span style="padding: 0 10px; color: #999">></span>' +
                    this.field.title
            }
        },
        theme() {
            return this.$store.getters['user/pref']('codeMirror_theme')
        },
        lineNumbers() {
            return this.$store.getters['user/pref']('codeMirror_lineNumbers')
        },
        lineWrapping() {
            return this.$store.getters['user/pref']('codeMirror_lineWrapping')
        }
    },
    asyncComputed: {
        item() {
            return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
                .then((obj) => {
                    return obj
                })
        }
    },
    created() {
        if (this.field.codeEditorButtonText) {
            this.setMode()
        }
    },
    mounted() {
        if (!this.field.codeEditorButtonText) {

            let indent = 2

            this.value = this.originalData[this.field.name]
            if (typeof this.value === 'object') {
                this.value = JSON.stringify(this.value, undefined, indent)
            }

            let options = {
                mode: 'application/json',
                lineNumbers: true,
                // Line wrapping must be enabled when presenting CodeMirror in a form else long lines will
                // stretch the form beyond the viewport.
                lineWrapping: true,
                // todo - Changes aren't being detected by the form so this has been disabled.
                readOnly: true,//ctrl.field.readonly,
                indentUnit: indent,
                tabMode: 'spaces',
                enterMode: 'indent',
                electricChars: false//, // Prevents '{' from resetting the indent
            }

            if (this.theme) {
                options['theme'] = this.theme
            }

            if (this.lineNumbers !== undefined) {
                options['lineNumbers'] = this.lineNumbers
            }

            if (this.lineWrapping !== undefined) {
                options['lineWrapping'] = this.lineWrapping
            }

            this.$nextTick(() => {
                CodeMirror.fromTextArea(this.$refs.codeMirror, options)
            })
        }
    },
    methods: {
        setMode() {
            if (this.tableName === 'themes_css') {
                this.$store.dispatch('itemData/get', {
                        tableName: 'themes_css',
                        id: this.id
                    })
                    .then((obj) => {
                        this.mode = obj.mode !== '' ? obj.mode : this.field.codeEditorMode
                    })
            } else {
                this.mode = this.field.codeEditorMode
            }
        }
    }
}
</script>

<style scoped>

</style>