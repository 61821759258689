<template>
    <select v-model.number="item.fieldId" class="form-select form-select-sm d-inline-block w-auto">
        <!--<option selected disabled value="0">Select&hellip;</option>-->
        <option :value="null">{{ component?.title }} items</option>
        <option disabled>──────────</option>
        <option v-for="field in fields" :value="field.id">{{ field.title }}</option>
    </select>
</template>

<script>
export default {
    name: "CollectionFilterFieldsMenu",
    props: {
        contentId: Number,
        item: Object
    },
    computed: {
        componentId() {
            return this.contentType?.componentId
        },
        component() {
            return this.$store.state.components.items.find(o => o.id === this.componentId)
        },
        fields() {
            return this.$store.getters['componentStructure/get'](this.componentId)
                .sort((a, b) => (a.title > b.title) ? 1 : -1)
                .filter((o) => {
                    const options = this.$store.getters['collectionFilters/typesOptions'](o.type, this.item.fieldId)
                    return options.length
                })
        }
    },
    asyncComputed: {
        content() {
            return this.$store.dispatch('itemData/get', {
                tableName: 'content',
                id: this.contentId
            })
        },
        contentType() {
            if (this.content) {
                return this.$store.dispatch('itemData/get', {
                    tableName: 'templates_templates',
                    id: this.content.contentType
                })
            }
        }
    }
}
</script>

<style scoped>

</style>